:root {
    --kob-color-default: #0090A1;
}

body {
    margin: 0;
    font-family: 'Albert Sans', sans-serif !important;
}

.holder {
    display: flex;
}

.holder .sidebar {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
    background: #FCFCFC;
    height: 100%;
    padding: 12px;
    box-sizing: border-box;
}

.holder .sidebar .menuIcon {
    width: 24px;
    height: 24px;
    padding: 12px;
    cursor: pointer;
    box-sizing: initial;
}

.holder .sidebar .logo {
    width: 36px;
    margin: 64px auto;
    cursor: pointer;
}

.holder .sidebar .menuList {
    border-bottom: 1px #B5E5E4 solid;
}

.holder .sidebar .menuList ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.holder .sidebar .menuList ul li {
    display: flex;
    cursor: pointer;
}

.holder .sidebar .menuList ul li .menuItemIcon {
    width: 24px;
    height: 24px;
    padding: 16px;
    margin: 0 -16px;
    box-sizing: initial;
}

.holder .sidebar .menuList ul li .menuItemText {
    display: none;
}

.holder .main {
    width: 100%;
    padding: 0 45px 0 115px;
    background-color: #fff !important;
    display: flex;
    gap: 30px;
    flex-direction: row;
    flex-wrap: wrap;
}

.holder .main .breadCrumb {
    position: fixed;
    display: flex;
    padding: 12px 0;
    background-color: #fff;
    width: 100%;
    z-index: 10;
    top: 0;
}

.holder .main .breadCrumb .breadCrumbItem {
    display: flex;
    height: 48px;
    align-items: center;
    padding: 0 40px 0 30px;
    border-bottom: 2px transparent solid;
}

.holder .main .breadCrumb .breadCrumbItem.active {
    border-bottom: 2px #0090A1 solid;
}

.holder .main .breadCrumb .breadCrumbItem .breadCrumbItemIcon {
    width: 24px;
    height: 24px;
}

.holder .main .breadCrumb .breadCrumbItem .breadCrumbItemText {
    color: #4B8D8C;
    font-weight: 500;
    font-size: 14px;
}

.holder .main .breadCrumb .breadCrumbItem.active .breadCrumbItemText {
    color: #14131D;
}

.holder .main .title {
    font-size: 24px;
    color: #14131D;
    font-weight: 400;
    margin-top: 88px;
}

.holder .main .content {
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.inputGroup {
    display: flex;
    height: 40px;
    align-items: center;
    padding: 10px 0;
    box-sizing: initial;
}

.inputGroup .inputLabel {
    width: 50%;
    font-size: 16px;
}

.inputGroup .inputItem {
    width: 50%;
    height: 40px;
    box-sizing: border-box;
    background: none;
    border: none;
    font-size: 16px;
    padding: 0 16px;
    font-family: 'Albert Sans', sans-serif;
}

.inputGroup.innerLabel {
    position: relative;
    flex-direction: column;
    height: auto;
    transition: all .2s ease-in-out;
}

.inputGroup.innerLabel.focus .inputLabel {
    font-size: 12px;
    line-height: 12px;
    height: auto;
    background: #fff;
    width: auto;
    top: 5px;
    left: 5px;
    padding: 0 6px;
}

.inputGroup.innerLabel .inputLabel {
    position: absolute;
    width: 100%;
    height: 56px;
    padding: 0 16px;
    font-size: 16px;
    margin-left: 2px;
    line-height: 58px;
    color: #0090A1;
}

.inputGroup.innerLabel .inputItem {
    width: 100%;
    height: 56px;
    border: 2px solid #0090A1;
    border-radius: 4px;
    font-size: 16px;
}

.inputGroup.innerLabel .inputSupport {
    width: 100%;
    font-size: 12px;
    color: #0090A1;
    text-align: left;
    padding: 0 16px;
    box-sizing: border-box;
}

.buttonGroup {
    display: flex;
    justify-content: flex-end;
}

.buttonGroup .buttonItem {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    font-size: 14px;
    height: 40px;
    padding: 0 24px;
    background-color: #0090A1;
    border-radius: 20px;
    margin: 45px 0 45px 8px;
    color: #fff;
    cursor: pointer;
}

.buttonGroup .buttonItem.secondary {
    background: transparent;
    color: #0090A1;
    border: 1px #0090A1 solid;
    box-sizing: border-box;
}

.buttonGroup .buttonItem.invert {
    background: #C58842;
}

.buttonGroup .buttonItem .buttonItemIcon {
    margin: 0 10px 0 -10px;
}

.holder .main .inputGroup {
    margin: 7px 0;
}

.holder .main .box {
    margin: 32px 0;
    padding: 12px;
    border: 1px solid rgba(121, 116, 126, 0.12);
    border-radius: 12px;
    flex-basis: 100%;
}

.holder .main .box .boxTitle {
    font-size: 16px;
    font-weight: 400;
    column-rule: #14131D;
    margin: 0;
}

.holder .main .box .fileGroup {
    display: flex;
    flex-direction: column;
    margin: 0 24px;
    padding: 20px 0;
    border-bottom: 1px #B5E5E4 solid;
}

.holder .main .box .fileGroup .fileItem {
    display: block;
    font-size: 14px;
    color: #4B8D8C;
    margin: 8px 0;
    text-decoration: none;
}

.holder .main .box .fileGroup .fileItem:hover {
    text-decoration: underline;
}

.holder .main .box .historyGroup {
    display: flex;
    flex-direction: column;
    margin: 0 24px 12px;
    padding: 20px 0;
    border-bottom: 1px #B5E5E4 solid;
}

.holder .main .box .historyGroup .historyItem {
    display: flex;
    font-size: 14px;
    color: #4B8D8C;
    margin: 8px 0;
}

.holder .main .box .historyGroup .historyItem .historyItemText {
    margin: 0 60px 0 10px;
}

.popupContainer .buttonItem {
    margin: 24px 0 0 8px;
}