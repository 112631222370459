.naoAutenticado {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    background: url(../images/kob-login-bg.jpg) center center;
    background-size: cover;

    h1, span {
        color: white;
        margin: auto;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 20px;
    }

    a {
        color: #0090a1;
        text-decoration: none;
    }

    &__logo {
        display: flex;
        height: 100%;
        align-items: center;
    }
}